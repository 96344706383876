import React from "react";
import Navbar from "../Navbar";
import './MeetingDetail.css';

function MeetingDetail() {
    return (
        <>
        <Navbar/>
            <div className="meeting_section">
                <div className="meeting_list_otr">
                    <div className="meeting_top_bar">
                        <div className="meeting_date_col">
                            <div className="meeting_date_box">
                                <h4>Meeting</h4>
                                {/* <!-- <h2>April 06 2020</h2> --> */}
                            </div>
                            <div className="meet_calender">
                                <label htmlFor="birthday">Meettin:</label>
                                <input type="date" id="meeting" name="birthday" />
                            </div>
                        </div>
                        <div className="right_meeting_box">
                            <div className="search_box">
                                <input type="text" placeholder="Serch Meeting" />
                            </div>
                            <a href="#." className="green_btn">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                                    <g fill="none" fillRule="evenodd">
                                        <g fill="#FFF" fillRule="nonzero">
                                            <g>
                                                <g>
                                                    <g>
                                                        <g>
                                                            <path d="M36 24.714L31.714 24.714 31.714 29 30.286 29 30.286 24.714 26 24.714 26 23.286 30.286 23.286 30.286 19 31.714 19 31.714 23.286 36 23.286z" transform="translate(-1045 -139) translate(282 120) translate(1) translate(736)" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                Add
                            </a>
                        </div>
                    </div>
                    <div className="date_info_main">
                        <h5>february 06 2021</h5>
                        <div className="date_info_box">
                            <div className="date_info_box_left">
                                <h6>Daily Standup</h6>
                                <div className="time_info">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.27985 0.0189325C5.73431 0.170303 4.30587 0.739883 3.05344 1.70416C2.44353 2.17374 1.65148 3.05623 1.19376 3.77612C0.996085 4.08703 0.611282 4.87487 0.473434 5.25085C0.319307 5.67128 0.155036 6.31376 0.0741992 6.81229C-0.0247331 7.42247 -0.0247331 8.58097 0.0741992 9.19115C0.214082 10.0537 0.435364 10.7511 0.814125 11.5231C1.22795 12.3665 1.65824 12.9643 2.34729 13.6532C3.03637 14.3421 3.63435 14.7723 4.47794 15.1861C5.25011 15.5647 5.94758 15.786 6.81036 15.9258C7.42067 16.0247 8.57943 16.0247 9.18974 15.9258C10.0525 15.786 10.75 15.5647 11.5222 15.1861C12.3657 14.7723 12.9637 14.3421 13.6528 13.6532C14.3419 12.9643 14.7721 12.3665 15.186 11.5231C15.5693 10.7418 15.7935 10.0312 15.9267 9.1755C16.0244 8.54795 16.0244 7.45549 15.9267 6.82794C15.7935 5.97224 15.5693 5.26168 15.186 4.48038C14.7721 3.63699 14.3419 3.03914 13.6528 2.35021C12.9637 1.66131 12.3657 1.23112 11.5222 0.817383C10.7507 0.439051 10.0395 0.213341 9.20539 0.08216C8.7452 0.00979267 7.71762 -0.0239495 7.27985 0.0189325ZM8.6262 1.11734C11.356 1.36652 13.7038 3.23152 14.5624 5.83273C15.6572 9.14955 14.1074 12.7561 10.943 14.2556C7.76862 15.7598 3.97172 14.6197 2.09902 11.6001C0.434863 8.91667 0.859458 5.3739 3.11555 3.1183C4.57227 1.66191 6.58516 0.931005 8.6262 1.11734ZM7.6087 3.21471C7.49471 3.24826 7.3624 3.36733 7.28808 3.50324C7.23686 3.59695 7.23301 3.77515 7.23301 6.06107C7.23301 7.41248 7.24356 8.55584 7.25649 8.60185C7.28764 8.7129 7.42061 8.86759 7.54198 8.93404C7.6314 8.98297 7.83117 8.98854 9.81589 8.99733C11.0126 9.00266 12.065 8.99821 12.1546 8.98747C12.6917 8.92303 12.8633 8.30875 12.4337 7.98801C12.349 7.92478 12.306 7.92315 10.338 7.90782L8.32878 7.89217L8.31312 5.71676C8.30185 4.15091 8.2873 3.52383 8.26122 3.47876C8.20348 3.3791 8.08683 3.28219 7.96148 3.22986C7.83384 3.17652 7.75053 3.17295 7.6087 3.21471Z" fill="black" />
                                    </svg>
                                    <span>3:34pm - 4:34pm</span>
                                </div>
                                <p>kedar@gmail.com</p>
                            </div>
                            <div className="date_info_box_right">
                                <a href="#.">Join</a>
                            </div>
                        </div>
                    </div>

                    <div className="date_info_main">
                        <h5>february 06 2021</h5>
                        <div className="date_info_box">
                            <div className="date_info_box_left">
                                <h6>Daily Standup</h6>
                                <div className="time_info">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.27985 0.0189325C5.73431 0.170303 4.30587 0.739883 3.05344 1.70416C2.44353 2.17374 1.65148 3.05623 1.19376 3.77612C0.996085 4.08703 0.611282 4.87487 0.473434 5.25085C0.319307 5.67128 0.155036 6.31376 0.0741992 6.81229C-0.0247331 7.42247 -0.0247331 8.58097 0.0741992 9.19115C0.214082 10.0537 0.435364 10.7511 0.814125 11.5231C1.22795 12.3665 1.65824 12.9643 2.34729 13.6532C3.03637 14.3421 3.63435 14.7723 4.47794 15.1861C5.25011 15.5647 5.94758 15.786 6.81036 15.9258C7.42067 16.0247 8.57943 16.0247 9.18974 15.9258C10.0525 15.786 10.75 15.5647 11.5222 15.1861C12.3657 14.7723 12.9637 14.3421 13.6528 13.6532C14.3419 12.9643 14.7721 12.3665 15.186 11.5231C15.5693 10.7418 15.7935 10.0312 15.9267 9.1755C16.0244 8.54795 16.0244 7.45549 15.9267 6.82794C15.7935 5.97224 15.5693 5.26168 15.186 4.48038C14.7721 3.63699 14.3419 3.03914 13.6528 2.35021C12.9637 1.66131 12.3657 1.23112 11.5222 0.817383C10.7507 0.439051 10.0395 0.213341 9.20539 0.08216C8.7452 0.00979267 7.71762 -0.0239495 7.27985 0.0189325ZM8.6262 1.11734C11.356 1.36652 13.7038 3.23152 14.5624 5.83273C15.6572 9.14955 14.1074 12.7561 10.943 14.2556C7.76862 15.7598 3.97172 14.6197 2.09902 11.6001C0.434863 8.91667 0.859458 5.3739 3.11555 3.1183C4.57227 1.66191 6.58516 0.931005 8.6262 1.11734ZM7.6087 3.21471C7.49471 3.24826 7.3624 3.36733 7.28808 3.50324C7.23686 3.59695 7.23301 3.77515 7.23301 6.06107C7.23301 7.41248 7.24356 8.55584 7.25649 8.60185C7.28764 8.7129 7.42061 8.86759 7.54198 8.93404C7.6314 8.98297 7.83117 8.98854 9.81589 8.99733C11.0126 9.00266 12.065 8.99821 12.1546 8.98747C12.6917 8.92303 12.8633 8.30875 12.4337 7.98801C12.349 7.92478 12.306 7.92315 10.338 7.90782L8.32878 7.89217L8.31312 5.71676C8.30185 4.15091 8.2873 3.52383 8.26122 3.47876C8.20348 3.3791 8.08683 3.28219 7.96148 3.22986C7.83384 3.17652 7.75053 3.17295 7.6087 3.21471Z" fill="black" />
                                    </svg>
                                    <span>3:34pm - 4:34px</span>
                                </div>
                                <p>kedar@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MeetingDetail;