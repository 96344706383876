import React from 'react';
import loginImage from "../assets/img/login.png";


function CommonImg() {
    return (
        <>
        <div className="login_img_right">
            <img src={loginImage} alt=""/>
        </div>
        </>
    )
}

export default CommonImg;